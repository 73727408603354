import { http } from '@/http/axios.js'
export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = '111'
export function getDictEnumListAPI(dict_key) {
  return http({
    url: '/company/basic/dictValue/dictEnums',
    method: 'get',
    params: { dict_key }
  }).then((res) => {
    return res.map((item) => {
      return {
        name: item.name,
        value: item.value
      }
    })
  })
}

// 停车场管理
// 是否支持预约
export const dict_is_reservable = [
  { name: '不支持', value: 0 },
  { name: '支持', value: 1 }
]
// 免密支付
export const dict_is_free_open = [
  { name: '未开通', value: 0 },
  { name: '已开通', value: 1 }
]
// 是否限时免停
export const dict_is_limited_free = [
  { name: '不支持', value: 0 },
  { name: '支持', value: 1 }
]
// 是否防占位
export const dict_is_anti_occupy = [
  { name: '不支持', value: 0 },
  { name: '支持', value: 1 }
]
// 是否充电限时免停
export const dict_is_charging_limited_free = [
  { name: '不支持', value: 0 },
  { name: '支持', value: 1 }
]
// 地锁状态
export const dict_charging_lock_status = [
  { name: '空闲', value: 0 },
  { name: '占用', value: 1 },
  { name: '锁定', value: 2 }
]
// 是否快充
export const dict_is_fast_charge = [
  { name: '否', value: 0 },
  { name: '是', value: 1 }
]
// 电枪状态
export const dict_gun_status = [
  { name: '空闲', value: 0 },
  { name: '占用', value: 1 },
  { name: '锁定', value: 2 },
  { name: '故障', value: 3 }
]
// 电桩状态
export const dict_pile_status = [
  { name: '离线', value: 0 },
  { name: '故障', value: 1 },
  { name: '空闲', value: 2 },
  { name: '充电', value: 3 }
]

// 协议
export const dict_agree_list = [
  { name: '隐私政策', key: 'privacy_agreement' },
  { name: '用户协议', key: 'wx_agreement' },
  { name: '关于我们', key: 'about_us' },
  { name: '版本号', key: 'version_number' }
]
/**
 * 订单管理
 */
// 支付状态
export const dict_order_status = [
  { name: '储值', key: 10 },
  { name: '充电消费', key: 20 },
  { name: '停车消费', key: 30 }
  // { name: '待支付', key: 0 },
  // { name: '已支付', key: 1 },
  // { name: '已完成', key: 2 },
  // { name: '已取消', key: 3 },
  // { name: '预约中', key: 4 },
  // { name: '系统自动取消', key: 5 },
]
// 预约订单-状态
export const dict_order_appoint_status = [
  { name: '预约中', key: 0 },
  { name: '系统取消', key: 1 },
  { name: '主动取消', key: 2 },
  { name: '预约成功', key: 3 }
]

/**
 * 用户管理
 */
// 用户列表-流水
export const dict_user_balance_type = [
  { name: '未知', key: 0 },
  { name: '充值', key: 1 },
  { name: '消费', key: 2 },
  { name: '提现', key: 3 }
]
