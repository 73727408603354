<template>
  <div class="wrapper">
    <!-- 充电桩 -->
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="充电站">
          <SelectStation v-model="searchData.station_id"></SelectStation>
        </el-form-item>
        <el-form-item label="枪状态">
          <el-select style="width: 200px" v-model="searchData.gun_status" placeholder="请选择" size="small" clearable>
            <el-option label="全部" :value="0"> </el-option>
            <el-option label="在线" :value="1"> </el-option>
            <el-option label="离线" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="桩编号">
          <el-input style="width: 200px" v-model="searchData.pile_number" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.pile_list_add)"
        @click="$router.push({ name: 'ChargePileAdd' })"
        type="primary"
        icon="el-icon-plus"
        size="small"
        >新增</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!-- 电枪 -->
    <el-dialog title="电枪配置" :visible.sync="dialogVisible" width="900px">
      <div class="fee-add-btn">
        <div></div>
        <el-button :disabled="pile_gun.length >= 2" type="primary" size="mini" @click="addGun">{{
          pile_gun.length >= 2 ? '最多添加2个' : '添加电枪'
        }}</el-button>
      </div>
      <div class="content">
        <div class="row">
          <div class="col">名称</div>
          <div class="col">电枪编号</div>
          <div class="col"></div>
        </div>
        <div class="row" v-for="(item, i) in pile_gun" :key="i">
          <div class="col">
            <el-input clearable style="width: 120px" v-model="item.name" size="small" placeholder="名称"></el-input>
          </div>
          <div class="col">
            <el-input clearable style="width: 160px" v-model="item.gun_number" size="small" placeholder="电枪编号"></el-input>
          </div>

          <div class="col last">
            <!-- <el-button type="primary" size="mini" @click="addTime(i)">添加</el-button> -->
            <!--<el-button type="danger" size="mini" @click="removeGun(i)">删除</el-button>-->
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveGun" size="small">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 二维码 -->
    <el-dialog :title="`${gun_info.pile_number}-${gun_info.gun_number}`" :visible.sync="show_qrcode" width="width">
      <el-divider content-position="center">充电枪二维码</el-divider>
      <div class="code">
        <img :src="gun_info.qr_code" alt="" />
      </div>
      <div class="download-btn">
        <el-button type="primary" size="small" @click="downloadCode">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dict_gun_status } from '@/enum/dict.js'
import { getListAPI, delAPI, updateGunAPI, downloadFile } from './api'
import SelectStation from '@/views/components/select-station.vue'
import qrcode from '@/assets/qr_code.png'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '80',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '电站名称',
    prop: 'charging_station',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['charging_station']?.['name']}</p>
    }
  },
  {
    label: '充电桩名称',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '充电桩编号',
    prop: 'pile_number',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['pile_number']}</p>
    }
  },
  {
    label: '充电枪',
    prop: 'pile_gun',
    minWidth: '300',
    customRender(h, row) {
      return (
        <div>
          <div class="gun_table" v-show={row['pile_gun'][0]}>
            <span>
              枪编号：
              <el-link onClick={() => this.$router.push({ name: 'ChargePileDetail', params: { id: row['id'] } })} type="primary" underline={false}>
                {row['pile_gun'][0]?.['gun_number']}
              </el-link>
            </span>
            <span style="margin:0 10px" onClick={() => this.showQrCode(row['pile_gun'][0])}>
              二维码：
              <img style="width:20px;height:20px; cursor: pointer;" src={qrcode} alt="" />
            </span>
            <span>
              状态：
              <el-tag type={this.filterGunStatus(row['pile_gun'][0]?.['status'])} size="mini">
                {row['pile_gun'][0]?.['status_dsc']}
              </el-tag>
            </span>
          </div>
          <div v-show={row['pile_gun'][1]}>
            <span>
              枪编号：
              <el-link onClick={() => this.$router.push({ name: 'ChargePileDetail', params: { id: row['id'] } })} type="primary" underline={false}>
                {row['pile_gun'][1]?.['gun_number']}
              </el-link>
            </span>
            <span style="margin:0 10px" onClick={() => this.showQrCode(row['pile_gun'][1])}>
              <img style="width:20px;height:20px; cursor: pointer;" src={qrcode} alt="" />
            </span>
            <span>
              状态：
              <el-tag type={this.filterGunStatus(row['pile_gun'][1]?.['status'])} size="mini">
                {row['pile_gun'][1]?.['status_dsc']}
              </el-tag>
            </span>
          </div>
        </div>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'create_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.pile_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'ChargePileEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>

          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.pile_list_config)}
            style="margin-left: 10px"
            underline={false}
            type="primary"
            onClick={() => this.handleGun(row)}
          >
            电枪配置
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  components: { SelectStation },
  mixins: [authBtnMixin],
  data() {
    return {
      show_qrcode: false,
      qrcode,
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        station_id: '',
        gun_status: 0
      },
      dict_gun_status,
      pile_number: '',
      pile_gun: [],
      gun_info: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    // this.getList()
  },
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { station_id, gun_status, pile_number } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, station_id, gun_status, pile_number }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleRemove({ id }) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 打开电枪配置dialog
    handleGun({ pile_number, pile_gun }) {
      this.pile_number = pile_number
      this.pile_gun = JSON.parse(JSON.stringify(pile_gun))
      this.dialogVisible = true
    },
    // 添加电枪
    addGun() {
      const obj = {
        gun_number: '',
        name: ''
      }
      this.pile_gun.push(obj)
    },
    // 删除电枪
    removeGun(i) {
      this.pile_gun.splice(i, 1)
    },
    // 保存电枪
    saveGun() {
      const data = {
        pile_number: this.pile_number,
        pile_gun: this.pile_gun
      }
      updateGunAPI(data).then(() => {
        this.$message.success('操作成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    filterGunStatus(status) {
      let type = 'primary'
      switch (status) {
        case 0:
          type = 'primary'
          break
        case 1:
          type = 'success'
          break
        case 2:
          type = 'danger'
          break
        case 3:
          type = 'warning'
          break

        default:
          break
      }
      return type
    },
    showQrCode(item) {
      this.gun_info = item
      this.show_qrcode = true
    },
    downloadCode() {
      downloadFile(this.gun_info.qr_code, `${this.gun_info.pile_number}-${this.gun_info.gun_number}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-bottom: 10px;
        .el-input,
        .el-form-item__content {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .el-dialog {
    .fee-add-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .content {
      padding: 0 10px;
      width: 100%;
      background-color: #fafafa;

      .row {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .col {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
  .gun_table {
    display: flex;
    align-items: center;
  }
  .code {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  .download-btn {
    text-align: center;
  }
}
</style>
