import { http } from '@/http/axios.js'
import axios from 'axios'
// 充电桩列表
export function getListAPI(params) {
  return http({
    url: '/company/charging/chargingPile/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/charging/chargingPile/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/charging/chargingPile/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/charging/chargingPile/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/charging/chargingPile/delete',
    method: 'post',
    data: { id }
  })
}
//绑定充电枪
export function updateGunAPI(data) {
  return http({
    url: '/company/chargingGun/batchAdd',
    method: 'post',
    data
  })
}

//充电记录
export function getChargeRecordAPI(params) {
  return http({
    url: '/company/order/orderCharge/list',
    method: 'get',
    params
  })
}
export function downloadFile(pdfUrl, name) {
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.png`)
    document.body.appendChild(link)
    link.click()
  })
}
